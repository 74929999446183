@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_controls.scss";
@use "src/_inv.scss";

body {
  @include theme.textFont;
  background: black;
}
strong, button, input {
  @include theme.altFont;
}

@include btn.Btn;
@include controls.Controls;
@include inv.Inv;

button.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
}

button.btn-inventory {
	position: fixed;
	bottom: 16px;
	left: 16px;
}

.dialog {
	max-width: 800px;
	margin: 128px auto 64px;
	&--false {
		display: none;
	}
	&__text {
		color: white;
		font-size: 18px;
		margin-left: 16px;
		max-width: calc(800px - 256px - 16px);
		display: inline-block;
	}
	&__option {
		display: block;
		width: 100%;
	}
	&__option + &__option {
		margin-top: 16px;
	}
	&__speaker {
		border-radius: 8px;
		filter: drop-shadow(0 0 4px white); 
		height: 256px;
		width: 256px;
		overflow: hidden;
		display: inline-block;
		img {
			height: 256px;
			width: 256px;
		}
	}
	&__active {
		border-bottom: 1px solid white;
		margin-bottom: 32px;
	}
	
}
// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {
  
  & + .btn {
    margin-left: 8px;
  }
}
.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w:1080px;

  z-index: 12;
  position: fixed;
  text-align: center;
  background: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  padding: 16px;
  box-sizing: border-box;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: min($like8px/4,2px) solid rgba(theme.$borderColor, 0.5);
    background: black;
    color: theme.$borderColor;
    padding: 0 $like8px;
    width: 100%;
    max-width: 640px;
    @include theme.mainFont;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: absolute;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} + 40px);
    right: calc(50vw - 640px/2 + 16px);
  }
  .btn--back {
    position: absolute;
    top: 64px;
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
  }

  .btn--approve{
    position: absolute;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter} + 16px);
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
   
    margin: 0;
    box-sizing:border-box;
  }

  .btn--deposit, .btn--withdraw {
    position: absolute;
    top: calc(50vh + 24px + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    left: calc(50vw - 640px/2);
    width: calc(100vw - 32px);
    max-width: 640px;
    margin: 0;
    box-sizing:border-box;

  }

  &__balance, &__poolbalance  {
    position: absolute;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: absolute;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    width: 100%;
    color: theme.$borderColor;
    font-size: $inputOverlayButtonSize;
  }


 &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
    .theInput__poolbalance {
      display: block;
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  &.INTENT__theInput--remove {
    .btn--deposit {
      display: none;
    }
    .btn--approve, .btn--withdraw {
      display: block;
    }
    .theInput__poolbalance {
      top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2 + #{$inputOverlayButtonSize}*2);
      display: block;
    }
    .theInput__balance, .theInput__allowance {
      display: block;
    }
  }
  .input-inner {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
  }
}


.pool {
	padding: 16px;
	text-align: center;
	color: white;
	h2 {
		margin-top: 128px;
		color: white;
		margin-left: 16px;
	}
	&__manage, &__harvest {
		height: 384px;
		width: 320px;
		display: inline-block;
		border: 1px solid white;
		border-radius: 8px;
		margin: 16px;
		vertical-align: top;
	}
	&-open--false {
		display: none;
	}
	&__token {
		position: relative;
		$r: 64px;
		height: 2*$r;
		width:  2*$r;
		overflow: hidden;
		border-radius: $r;
		margin: 48px auto;
		img {
			height: 2*$r;
			width: 2*$r;
		}
		img + img {
			position: absolute;
			height: $r;
			width: $r;
			right: $r/7;
			bottom: $r/6;
			filter: drop-shadow(0 0 4px #6348B1);

		}
		filter: drop-shadow(0 0 4px white);

	}
	.pool__btn {
		& + .pool__btn {
			margin-left: 16px;
		}
	}
	&__manage {
		.pool__btn {
			width: 52px;

		}
	}
	strong {
		margin-bottom: 32px;
		display: block;
		font-size: 20px;
	}


}

.pending {
	display: none;
	&--true {
		display: block;
	}
	position: fixed;
	top: 0;
	height: 64px;
	font-size: 24px;
	line-height: 64px;
	left: 0;
	width: 100%;
	text-align: center;
	color: black;
	background: white;
	z-index: 999;
}

.start-over {
	position: fixed;
	top: 16px;
	left: 16px;
}

