@use "src/_theme.scss";

@mixin buttonSizing ($fontSize) {
  $fS: $fontSize;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/12;
  padding: $pV $pH;
  border-radius: $pV;
  border: min($bW,1px) solid rgba(theme.$borderColor, 0.4);
  font-size: $fS;
  border-radius: $fontSize/4;

}
 
@mixin buttonStyling {
	cursor: pointer;
	outline: none;

    background: black;
    
    color: white;

     transform: scale(1) translateX(0) translateY(0);;
  transition: 0.2s transform ease-in-out,
  			  0.2s filter ease-in-out,
              0.2s background ease-in-out, 
              0.2s border ease-in-out, 
              0.2s color ease-in-out;

    @include theme.buttonFont;
    
    &:hover {
    	background: white;
    
      color: black;
    	border-color: theme.$buttonBorderHOVER;
    	transform: scale(1) translateX(0) translateY(2px);
    	filter: drop-shadow(0 0 0 black);
    }
    filter: drop-shadow(2px 2px 2px black);


}

@mixin buttonShadow {
	
}

@mixin tlbr($indent) {
  top: $indent;
  left: $indent;
  right: $indent;
  bottom: $indent;  
}

@mixin TLBR {
  @include tlbr(0);
}

@mixin f_tlbr($indent) {
  @include tlbr($indent);
  position: fixed;    
}

@mixin F_TLBR {
  @include f_tlbr(0);
}

@mixin Toggly {
  transform: translateY(0);
  transition: 0.2s transform ease-in-out;

  &--false {
    transform: translateY(100vh);
  }
}

@mixin TOGGLY {
  @include F_TLBR;
  transform: translateY(0);
  transition: 0.2s transform ease-in-out;

  &--false {
    transform: translateY(100vh);
  }
}


@mixin scrolly {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding: 1px;

  }

  &::-webkit-scrollbar-button {

  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.6);
    border-radius: 8px;
    border:none;

  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, theme.$blue, theme.$pink, theme.$green);
    padding: 1px;

  }
  &::-webkit-scrollbar-track-piece {
    

  }
  &::-webkit-scrollbar-corner {


  }
}
